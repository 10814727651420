<template>
  <div>
    <v-col cols="12" class="pa-5">
      <b-form-select
        v-model="selected_site_id"
        :options="userSiteCollection"
        v-on:change="changeSite"
      ></b-form-select>
      <!-- <pre>
        {{ userSiteCollection }}
      </pre> -->
    </v-col>
    <ul class="menu-nav">
      <li
        v-if="permissionCan('module.pages.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/page') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-edit-1"></i>
          <span class="menu-text">{{ $t("MENU.PAGE_ADMINISTARATION") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('pages.page.menu') && selected_site_id > 0"
              to="/cms/pages/pages"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-web"></i>
                  <span class="menu-text">{{ $t("MENU.PAGES") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="
                permissionCan('pages.pageTemplate.menu') && selected_site_id > 0
              "
              to="/cms/pages/pageTemplates"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-file-2"></i>
                  <span class="menu-text">{{ $t("MENU.PAGE_TEMPLATES") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.blog.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/blog') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list"></i>
          <span class="menu-text">Blog</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('blog.post.menu')"
              to="/cms/blog/posts"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.POSTS") }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('blog.category.menu')"
              to="/cms/blog/categories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.BLOG_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('blog.tag.menu')"
              to="/cms/blog/tags"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-tag"></i>
                  <span class="menu-text">{{ $t("MENU.TAGS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('blog.setting.menu')"
              to="/cms/blog/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.news.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/news') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list-2"></i>
          <span class="menu-text">{{ $t("MENU.NEWS") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- News-->
            <router-link
              v-if="permissionCan('news.news.menu')"
              to="/cms/news/news"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.POSTS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News-->
            <!-- News Category -->
            <router-link
              v-if="permissionCan('news.category.menu')"
              to="/cms/news/categories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.NEWS_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News Category -->
            <!-- News tags -->
            <router-link
              v-if="permissionCan('news.tag.menu')"
              to="/cms/news/tags"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-tag"></i>
                  <span class="menu-text">{{ $t("MENU.TAGS") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('news.setting.menu')"
              to="/cms/news/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF News tags -->
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.publication.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/publications'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-list-3"></i>
          <span class="menu-text">{{ $t("MENU.PUBLICATION") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- Publication Post -->
            <router-link
              v-if="permissionCan('publication.post.menu')"
              to="/cms/publications/publications"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-edit"></i>
                  <span class="menu-text">{{ $t("MENU.POSTS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Post-->
            <!-- Publication Category -->
            <router-link
              v-if="permissionCan('publication.category.menu')"
              to="/cms/publications/publicationCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-interface-3"></i>
                  <span class="menu-text">{{
                    $t("MENU.PUBLICATION_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Category -->
            <!-- Publication Settings -->
            <router-link
              v-if="permissionCan('publication.setting.menu')"
              to="/cms/publications/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF Publication Settings -->
          </ul>
        </div>
      </li>

      <router-link
        v-if="permissionCan('system.mediaLibrary.menu') && selected_site_id > 0"
        to="/cms/media_library"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-folder"></i>
            <span class="menu-text">{{ $t("MENU.MEDIA_LIBRARY") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="permissionCan('module.galleries.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/banners') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-squares"></i>
          <span class="menu-text">{{ $t("MENU.GALLERIES") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('galleries.category.menu') && selected_site_id > 0
              "
              to="/cms/galleries"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-squares"></i>
                  <span class="menu-text">{{ $t("MENU.GALLERIES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('galleries.setting.menu')"
              to="/cms/galleries/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="permissionCan('pages.menu.menu') && selected_site_id > 0"
        to="/cms/menus"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-menu-3"></i>
            <span class="menu-text">{{ $t("MENU.MENUS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="
          permissionCan('module.banner.menu') ||
          permissionCan('pages.banner.menu')
        "
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/cms/banners') }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-speech-bubble"></i>
          <span class="menu-text">{{ $t("MENU.BANNERS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('pages.banner.menu') && selected_site_id > 0"
              to="/cms/banners/banners"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i> -->
                  <i class="menu-icon flaticon-chat-1"></i>
                  <span class="menu-text">{{ $t("MENU.banners") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('pages.bannerType.menu') && selected_site_id > 0
              "
              to="/cms/banners/bannerTypes"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i> -->
                  <i class="menu-icon flaticon-tabs"></i>
                  <span class="menu-text">{{ $t("MENU.bannerTypes") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="
          permissionCan('contactForms.contactForm.menu') && selected_site_id > 0
        "
        to="/cms/contactForms"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon-multimedia-2"></i>
            <span class="menu-text">{{ $t("MENU.contact_form") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="permissionCan('module.quotations.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/quotations'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-hangouts-logo"></i>
          <span class="menu-text">{{ $t("MENU.QUOTATIONS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('quotations.quotation.menu') &&
                selected_site_id > 0
              "
              to="/cms/quotations/quotations"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-sheet"></i>
                  <span class="menu-text">{{ $t("MENU.quotations") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('quotations.quotationCategory.menu') &&
                selected_site_id > 0
              "
              to="/cms/quotations/quotationCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-files-and-folders"></i>
                  <span class="menu-text">{{
                    $t("MENU.QUOTATION_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="permissionCan('logos.category.menu') && selected_site_id > 0"
        to="/cms/logos"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-image-file"></i>
            <span class="menu-text">{{ $t("MENU.LOGOS") }}</span>
          </a>
        </li>
      </router-link>

      <li
        v-if="permissionCan('module.external_references.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/externalReferences'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-clip-symbol"></i>
          <span class="menu-text">{{ $t("MENU.EXTERNAL_REFERENCES") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('external_references.category.menu') &&
                selected_site_id > 0
              "
              to="/cms/externalReferences"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-clip-symbol"></i>
                  <span class="menu-text">{{
                    $t("MENU.EXTERNAL_REFERENCES")
                  }}</span>
                </a>
              </li>
            </router-link>

            <!-- externalReferences Settings -->
            <router-link
              v-if="permissionCan('external_references.setting.menu')"
              to="/cms/externalReferences/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF external_references Settings -->
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.faq.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/faq'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-talk"></i>
          <span class="menu-text">{{ $t("MENU.FAQ") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('faq.category.menu') && selected_site_id > 0"
              to="/cms/faq"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon2-talk"></i>
                  <span class="menu-text">{{ $t("MENU.FAQ") }}</span>
                </a>
              </li>
            </router-link>

            <!-- FAQ Settings -->
            <router-link
              v-if="permissionCan('faq.setting.menu')"
              to="/cms/faq/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF FAQ Settings -->
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.instructor.menu') || 1 == 1"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/instructors'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-customer"></i>
          <span class="menu-text">{{ $t("MENU.INSTRUCTOR") }}</span>
          <i class="menu-arrow"></i>
        </a>

        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('instructors.instructor.menu') &&
                selected_site_id > 0
              "
              to="/cms/instructors"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-customer"></i>
                  <span class="menu-text">{{ $t("MENU.INSTRUCTOR") }}</span>
                </a>
              </li>
            </router-link>

            <!-- FAQ Settings -->
            <router-link
              v-if="permissionCan('instructors.setting.menu')"
              to="/cms/instructors/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
            <!-- END OF FAQ Settings -->
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.colleagues.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cms/colleagues'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-user"></i>
          <span class="menu-text">{{
            $t("MENU.COLLEAGUES_ADMINISTRATION")
          }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="
                permissionCan('colleagues.colleague.menu') &&
                selected_site_id > 0
              "
              to="/cms/colleagues/colleagues"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-users"></i>
                  <span class="menu-text">{{ $t("MENU.COLLEAGUES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="
                permissionCan('colleagues.colleagueCategory.menu') &&
                selected_site_id > 0
              "
              to="/cms/colleagues/colleagueCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-layers"></i>
                  <span class="menu-text">{{
                    $t("MENU.COLLEAGUE_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('colleagues.setting.menu')"
              to="/cms/colleagues/settings/"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-settings-1"></i>
                  <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <li
        v-if="permissionCan('module.gdpr.menu')"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/cookieManagement'),
        }"
      >
        <a v-if="selected_site_id > 0" href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-settings-1"></i>
          <span class="menu-text">{{ $t("MENU.SETTINGS") }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div v-if="selected_site_id > 0" class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <router-link
              v-if="permissionCan('gdpr.code.menu')"
              to="/cms/cookieManagement/cookies"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon-list-2"></i>
                  <span class="menu-text">{{ $t("MENU.COOKIES") }}</span>
                </a>
              </li>
            </router-link>
            <router-link
              v-if="permissionCan('gdpr.category.menu') && selected_site_id > 0"
              to="/cms/cookieManagement/cookieCategories"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-indent-dots"></i>
                  <span class="menu-text">{{
                    $t("MENU.COOKIE_CATEGORIES")
                  }}</span>
                </a>
              </li>
            </router-link>

            <router-link
              v-if="permissionCan('system.site.menu') && selected_site_id > 0"
              to="/cms/cookieManagement/settings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
                  <i class="menu-icon flaticon-settings"></i>
                  <span class="menu-text">{{ $t("MENU.PAGE_SETTINGS") }}</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>

      <router-link
        v-if="permissionCan('system.user.menu') && selected_site_id == 0"
        to="/settings/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <!-- <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i> -->
            <i class="menu-icon flaticon2-user-1"></i>
            <span class="menu-text">{{ $t("MENU.users") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.userGroup.menu') && selected_site_id == 0"
        to="/settings/userGroups"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-group"></i>
            <span class="menu-text">{{ $t("MENU.userGroups") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.role.menu') && selected_site_id == 0"
        to="/settings/roles"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-interface-8"></i>
            <span class="menu-text">{{ $t("MENU.roles") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.currency.menu') && selected_site_id == 0"
        to="/settings/currency"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-coins"></i>
            <span class="menu-text">{{ $t("MENU.currencies") }}</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="permissionCan('system.country.menu') && selected_site_id == 0"
        to="/settings/country"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-earth-globe"></i>
            <span class="menu-text">{{ $t("MENU.countries") }}</span>
          </a>
        </li>
      </router-link>
    </ul>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import SiteService from "@/core/services/site.service.js";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTMenu",
  // props: ["selected_site_id"],
  data() {
    return {
      permissions: [],
      // selected_site_id: -1,
      selected_site_id: SiteService.getActiveSiteId(),
      system_settings: {
        id: 0,
        name: this.$t("FORMS.system_settings"),
      },
      loader: false,
    };
  },

  computed: {
    userSiteCollection() {
      let items = [];
      items = [...this.$store.getters.authUserSites];
      if (
        this.permissionCan("system.setting.menu") &&
        !items.find((item) => item.id == 0)
      ) {
        items.push(this.system_settings);
      }
      return items.map((item) => ({
        value: item.id,
        text: item.name,
        locales: item.locales,
      }));
    },
  },

  methods: {
    changeSite(val) {
      this.loader = true;
      // window.localStorage.setItem("active_site_id", val);
      SiteService.setActiveSiteId(val);
      i18nService.setSiteLocals(
        this.userSiteCollection.find((item) => item.value == val).locales
      );

      // if (this.$route.name != "dashboard") {
      //   this.$router.push({ name: "dashboard" });
      // }
      // window.location.reload();
      console.log(window.location.origin);
      window.location.href = window.location.origin;
    },

    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => {
            return (
              item.name.indexOf("menu") > -1 || item.name.indexOf("modul") > -1
            );
          }
        );
      }
    },
  },

  mounted() {
    // this.selected_site_id = SiteService.getActiveSiteId();
    this.setPermissions();
  },
};
</script>
